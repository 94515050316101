import React, {useContext} from 'react';
import Dinesh from '../img/about/dinesh2.jpg';

import { Link } from 'react-router-dom';

import {motion} from 'framer-motion';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
import { GrContact } from "react-icons/gr";


const About = () => {

  const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext);
  
  return <motion.section
  initial={{opacity:0, y: '100%'}}
  animate={{opacity:1,y:0}}
  transition={transition1}
  exit={{opacity:0,y: '100%'}}
   className='section overflow-auto xl:overflow-hidden'>
    <div 
     onMouseEnter={mouseEnterHandler}
     onMouseLeave={mouseLeaveHandler}
     className="container mx-auto relative">
      <div className='flex flex-col lg:flex-row h-auto items-center justify-center gap-x-24 text-center lg:text-left lg:pt-16'>
        <div className='flex-1 max-h-80 lg:max-h-max order-2 lg:order-none xl:overflow-hidden'>
          <img src={Dinesh} alt="" className='lg:mt-0 xl:rounded-full'/>
        </div>
        <motion.div
          initial={{opacity:0, y: '-80%'}}
          animate={{opacity:1,y:0}}
          transition={transition1}
          exit={{opacity:0,y: '-80%'}}
          className='flex-1 pt-36 pb-14 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'>
          <h1 className='h1'>About Me</h1>
          <p className='max-w-sm mb-12  text-justify'>As a seasoned tour guide with over two decades of dedicated service in Sri Lanka's vibrant tourism industry, I bring a wealth of experience and passion to every journey. Since embarking on my career in 2001, I've cultivated a deep expertise in leading captivating wildlife and cultural tours, allowing travelers to immerse themselves fully in the rich tapestry of Sri Lanka's natural beauty and heritage. With me as your guide, expect nothing less than an unforgettable adventure, blending expert knowledge, personalized attention, and boundless enthusiasm for sharing the wonders of this remarkable island paradise.</p>
          <br/>
          <Link to={'/contact'} className='btn mb-[30px] text-white'>Contact </Link>
        </motion.div>
      </div>
    </div>
  </motion.section>;
};

export default About;
