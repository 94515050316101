import React, {useState} from 'react';

import { IoMdClose } from "react-icons/io";
import { CgMenuRight } from "react-icons/cg";

import { Link } from "react-router-dom";

import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const menuVariants = {
  hidden : {
    x: '100%'
  },
  show: {
    x: 0,
    transition: {
      ease: [0.6,0.01,-0.05,0.9]
    },
  },
}

const MobileNav = () => {

  const [ openMenu, setOpenMenu ]  = useState(false);
  const navigate = useNavigate();

  const handleRouts = (route) => {
    navigate(route);
    setOpenMenu(false);
  }

  return (
    <nav className='text-primary xl:hidden'>
    <div onClick={() => setOpenMenu(true) } className='text-3xl cursor-pointer'>
      <CgMenuRight />
    </div>
    <motion.div 
    variants={menuVariants}
    initial={'hidden'}
    animate={openMenu?'show':''} 
    className='bg-white shadow-2xl w-full absolute top-0 right-0 max-w-xs h-screen z-20'>

      <div onClick={() => setOpenMenu(false) } className='text-4xl absolute z-30 left-4 top-14 text-primary cursor-pointer'>
        <IoMdClose />
      </div>
      <ul className='h-full flex flex-col justify-center items-center gap-y-8 text-primary font-bold text-3xl'>
        <li>
          <a onClick={() => handleRouts('/')} className='cursor-pointer'>Home</a>
        </li>
        <li>
          <a onClick={() => handleRouts('/about')} className='cursor-pointer'>About</a>
        </li>
        <li>
          <a onClick={() => handleRouts('/reviews')} className='cursor-pointer'>Reviews</a>
        </li>
        <li>
          <a onClick={() => handleRouts('/gallery')} className='cursor-pointer'>Gallary</a>
        </li>
        <li>
          <a onClick={() => handleRouts('/contact')} className='cursor-pointer'>Contact</a>
        </li>
      </ul>
    </motion.div>
    </nav>
  )
};

export default MobileNav;
