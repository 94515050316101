import React, {useContext} from 'react';

import {ImFacebook,ImYoutube,ImInstagram} from 'react-icons/im';
import { CursorContext } from '../context/CursorContext';

const Socials = () => {

  const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext);

  return (<div
    onMouseEnter={mouseEnterHandler}
    onMouseLeave={mouseLeaveHandler}
   className='hidden xl:flex ml-24'>
    <ul className='flex gap-x-2'>
      <li className='border-2 border-current rounded-full flex items-center p-2'>
        <a href='#' target='_blank' ><ImFacebook /></a>
      </li>
      <li className='border-2 border-current rounded-full flex items-center p-2'>
        <a href='#' target='_blank' ><ImYoutube /></a>
      </li>
      <li className='border-2 border-current rounded-full flex items-center p-2'>
        <a href='#' target='_blank' ><ImInstagram /></a>
      </li>
    </ul>
  </div>)
};

export default Socials;
