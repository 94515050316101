import React, { useContext } from 'react';
import {Link} from 'react-router-dom';

import Sigiri from '../img/home/sigiri2.jpg';
import Breakfast from '../img/home/breakfast.jpg';
import Fishing from '../img/home/fishing.jpg';
import Sripada from '../img/home/sripada.jpg';
import Beach from '../img/home/beach.jpg';

import {motion} from 'framer-motion';
import { transition1 } from '../transitions';
//import { CursorContext } from '../context/CursorContext';

import { EffectFade , Pagination, Autoplay, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

const Home = () => {

  //const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext);

  return (<motion.section 
    initial={{opacity:0}}
    animate={{opacity:1}}
    transition={transition1}
    exit={{opacity:0}}
    className='section overflow-auto h-screen xl:overflow-hidden'>
    <div className='container mx-auto h-full relative'>
      {/* text and img wrapper */}
      <div className='absolute bottom-60 left-40 w-72 h-72 bg-blue-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob' ></div>
      <div className='absolute bottom-40 -left-0 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob animation-delay-2000' ></div>
      <div className='absolute bottom-0 left-20 w-72 h-72 bg-green-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob animation-delay-4000' ></div>
      
      <div className='flex flex-col xl:flex-col justify-center '>
          <motion.div
            initial={{opacity:0, y: '-50%'}}
            animate={{opacity:1,y:0}}
            transition={transition1}
            exit={{opacity:0,y: '-50%'}}
            // onMouseEnter={mouseEnterHandler}
            // onMouseLeave={mouseLeaveHandler}
            className='w-full pt-36 pb-14 lg:pt-0 lg:pb-0 lg:w-auto z-10 lg:absolute flex flex-col justify-center items-center lg:items-start'>
            <h1 className='h1'>SriBreeze</h1>
            <p className='text-[26px] lg:text-[36px] font-primary '>Sri Lankan Travel & Tours.</p>
            <p className='text-[15px] lg:text-[18px] font-primary mb-6'>Specialized in English and German languages.</p>
            <Link to={'/contact'} className='btn mb-[30px]'> Contact Me </Link>
          </motion.div>



          <div className='flex max-h-96 lg:max-h-max bg-gray-800 w-screen xl:w-[619px] items-center xl:ml-96 overflow-hidden'>
            {/* <motion.div
              initial={{scale:0}}
              animate={{scale:1}}
              transition={transition1}
              exit={{scale:0}}
              className='relative lg:right-40 '>
              <motion.img
               whileHover={{scale: 1.1}}
               src={Sigiri} alt='' />
            </motion.div> */}

          

            <Swiper
              modules={[EffectFade, A11y, Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}         
              mousewheel={true}
              effect="fade"
            >
            <SwiperSlide >
              <motion.img
                whileHover={{scale: 1.1}}
                src={Sigiri} alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <motion.img
                whileHover={{scale: 1.1}}
                src={Breakfast} alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <motion.img
                whileHover={{scale: 1.1}}
                src={Fishing} alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <motion.img
                whileHover={{scale: 1.1}}
                src={Sripada} alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <motion.img
                whileHover={{scale: 1.1}}
                src={Beach} alt='' />
            </SwiperSlide>
          </Swiper>

        
          </div>

         

          


      </div>
    </div>
  </motion.section>)



};

export default Home;
