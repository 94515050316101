import React,{useContext} from 'react';

// import components
import Socials from './Socials';
import Logo from '../img/header/logo.png';
import MobileNav from './MobileNav';

// import links
import {Link} from 'react-router-dom';
import { CursorContext } from '../context/CursorContext';
import { useUser } from '../context/UserContext';

const Header = () => {

  const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext);
  const { login, logout , isAuthenticated } = useUser();

  return <header className='fixed w-full px-[30px] lg:px-[100px] z-30 h-[100px] lg:h-[140px] flex items-center bg-white xl:bg-transparent'>
    <div className="flex lg:flex-row lg:item-center w-full justify-between">
      <Link
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
       to='/' className='max-w-[200px] text-xl'>
        <img src={Logo} alt='' />
       
      </Link>
      <nav 
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className='hidden xl:flex gap-x-4 font-semibold items-center'>
        <Link to={'/'} className='text-[#ffffff] transition bg-[#0a93a6] pt-2  pb-2 pl-4 pr-4 rounded-2xl hover:bg-slate-900 '>Home</Link>
        <Link to={'/about'} className='text-[#ffffff] transition bg-[#0a93a6] pt-2  pb-2 pl-4 pr-4 rounded-2xl hover:bg-slate-900'>About</Link>
        <Link to={'/reviews'} className='text-[#ffffff] transition bg-[#0a93a6] pt-2  pb-2 pl-4 pr-4 rounded-2xl hover:bg-slate-900'>Reviews</Link>
        <Link to={'/gallery'} className='text-[#ffffff] transition bg-[#0a93a6] pt-2  pb-2 pl-4 pr-4 rounded-2xl hover:bg-slate-900'>Gallary</Link>
        <Link to={'/contact'} className='text-[#ffffff] transition bg-[#0a93a6] pt-2  pb-2 pl-4 pr-4 rounded-2xl hover:bg-slate-900'>Contact</Link>
        {/* {!isAuthenticated && 
          <Link to={'/auth/login'} className='text-[#ffffff] transition bg-[#0a93a6] pt-2  pb-2 pl-4 pr-4 rounded-2xl'>Login</Link>
        }
        {isAuthenticated && 
          <Link to={'/auth/logout'} className='text-[#ffffff] transition bg-[#0a93a6] pt-2  pb-2 pl-4 pr-4 rounded-2xl'>Logout</Link>
        } */}
      </nav>

      <Socials />
      <MobileNav />
    </div>
    </header>
};

export default Header;
