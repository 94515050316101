import React,{useState,useEffect} from 'react';
import { useUser } from '../../context/UserContext';
import { useLocation,useNavigate } from 'react-router-dom';
import axios from "axios";
const BASE_URL = process.env.REACT_APP_BACKEND_API


const Callback = () => {

  const location = useLocation();
  const { login, user } = useUser();

  const [completed,setCompleted] = useState(false);

  const navigate = useNavigate();

  useEffect(() =>  {
    const queryParams = new URLSearchParams(location.search);
    const accessToken = queryParams.get('access_token');
    

    // Make an API request to get user details from the Strapi backend
    googleLogin(accessToken);
    //navigate("/add-review");
   

  }, [location.search]);


  // useEffect(() =>  {
  //   if(completed){
  //     navigate("/add-review");
  //   }
  // }, [completed]);


  const googleLogin = async (accessToken) => {

  
    try {

      await axios.get(`${BASE_URL}auth/google/callback?access_token=${accessToken}`).then(response =>{

          login(response.data);
        
          if(response.status===200){ 
            setCompleted(true);      
            //navigate("/add-review");
            console.log('success');
            window.location.replace("https://sribreezelankatours.com/add-review");
          }

      });

    } catch (error) {
      console.error('Error fetching user details:', error);
    }

  }
  
  return (
    <></>
  )
}

export default Callback