import {useContext,useEffect} from 'react';

// import components
import Header from "./components/Header";
import AnimRoutes from "./components/AnimRoutes";
import Footer from "./components/Footer";

// import router
import { BrowserRouter as Router } from 'react-router-dom';

// import motion
import { motion } from 'framer-motion';
import { CursorContext } from './context/CursorContext';

// notifications
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useUser } from './context/UserContext';



const App = () => {
  const {cursorVarients, cursorBG} = useContext(CursorContext);
  //const { isAuthenticated,jwt } = useUser();

  // useEffect(() => {   
  //   localStorage.setItem('isAuthenticated',isAuthenticated);
  //   localStorage.setItem('isAuthenticated',isAuthenticated);
  // }, [useUser])
  
  return (<> 
    <Router>
      <ReactNotifications />
      <Header />
      <AnimRoutes />
      <Footer />
    </Router>
    {/* {coursor} */}
    <motion.div
    variants={cursorVarients}
    animate={cursorBG}
     className='w-[32px] h-[32px] bg-primary fixed top-0 left-0 pointer-events-none z-50 rounded-full'></motion.div>
  </>);
};

export default App;
