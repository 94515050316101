import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_API
const user = JSON.parse(localStorage.getItem('user'));
//console.log(user?.jwt);


export const API = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-type': 'application/json'}
});

export const API_AUTH = axios.create({
    baseURL: BASE_URL,
   // headers: { 'Content-type': 'application/json'},
    headers: { 'Content-type': 'application/json', Authorization: `Bearer ${user?.jwt}` },
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-type': 'application/json'},
    withCredentials : true
});