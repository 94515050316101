import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { useUser } from '../../context/UserContext';

function Logout() {

    const { logout } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
   
        logout();
        navigate(`/auth/login`);

    },[]);
  
    return (
        <div>Loggin Out..!</div>
    )
}

export default Logout