import React, {useContext} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {motion} from 'framer-motion';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
import { useUser } from '../context/UserContext';
import ReactStars from "react-rating-stars-component";
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

//import {API_AUTH} from '../network/api';
const BASE_URL = process.env.REACT_APP_BACKEND_API;
const auth_user = JSON.parse(localStorage.getItem('user'));


const contactFormValidation = Yup.object({   
    comment: Yup
        .string()
        .required('Your Comment is required.'),
    name: Yup
        .string()
        .required('Your name is required.'),
    rate: Yup
        .number()
        .required('Your rate is required.')
});

function AddReviews() {

    //const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext);
    const navigate = useNavigate();
    const { user } = useUser();

    const formik = useFormik({
        initialValues: {
            rate: null,
            comment: '',
            name: '',
            submit: null
        },

        validationSchema: contactFormValidation,
        onSubmit: async (values, helpers) => {
            //console.log(values);
            // dispatch({ type: 'LOADING' });

            const data = {
                "data":{                 
                    review:values.comment,
                    is_active: 1,
                    vote: values.rate,
                    name: values.name 
                }
            }

            const axiosConfig = {
                headers: { 'Content-type': 'application/json', Authorization: `Bearer ${auth_user?.jwt}` },
            };

            try {
                         
                await axios.post(`${BASE_URL}reviews`,data,axiosConfig).then((response) => {
                    console.log(response);

                    Store.addNotification({
                        title: "Successfull!",
                        message: "Yor comment successfully added.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 3000,
                          onScreen: true
                        }
                    });

                    formik.resetForm();
                    navigate("/reviews");
                  
                }, (error) => {
                    console.log(error);
                }); 
                         

            } catch (err) {
            
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
                // dispatch({ type: 'LOADED' });
            }
        }

    });


    const ratingChanged = (newRating) => {
        formik.setFieldValue('rate',newRating);
    };



    return <motion.section 
    initial={{opacity:0,y:'100%'}}
    animate={{opacity:1,y:'0'}}
    transition={transition1}
    exit={{opacity:0,y: '100%'}}
    className='section bg-white overflow-hidden'>
    <div className='container h-full mx-auto'> 
      <div className='flex flex-col lg:flex-row h-full item-center justify-start pt-36 gap-x-8 text-center lg:text-left'>

        <div className='absolute bottom-60 left-40 w-72 h-72 bg-blue-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob' ></div>
        <div className='absolute bottom-40 -left-0 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob animation-delay-2000' ></div>
        <div className='absolute bottom-0 left-20 w-72 h-72 bg-green-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob animation-delay-4000' ></div>

        <motion.div
          initial={{opacity:0,y:'100%'}}
          animate={{opacity:1,y:'0'}}
          transition={transition1}
          exit={{opacity:0,y: '100%'}} 
          className='hidden lg:flex bg-[#eef7f9] absolute bottom-0 left-0 right-0 top-60 z-[-1]'>

        </motion.div>
        <div 
        // onMouseEnter={mouseEnterHandler}
        // onMouseLeave={mouseLeaveHandler}
        className='lg:pt-6 px-4 w-[100%] z-20'>

            <h1 className='h2 mb-10'>Add New Review</h1>
            <form  className='flex flex-col gap-y-4' onSubmit={formik.handleSubmit}>

                <div>
                    <textarea 
                        name="comment"
                        className={'outline-none border-2 border-primary h-[150px] bg-transparent font-secondary w-full placeholder:text-[#757879] rounded-md p-2' }
                        type="text"
                        placeholder='Your Comment'
                        {...formik.getFieldProps("comment")}
                        />
                    {formik.touched.comment && formik.errors.comment ? (
                        <div className='error'>{formik.errors.comment}</div>
                    ) : null}
                </div>

                <div>
                    <input
                        type="text"
                        name="name"
                        className={'outline-none border-2 border-primary bg-transparent font-secondary w-[50%] placeholder:text-[#757879] rounded-md p-2' }
                        placeholder='Your Name'
                        {...formik.getFieldProps("name")}
                        />
                    {formik.touched.name && formik.errors.name ? (
                        <div className='error'>{formik.errors.name}</div>
                    ) : null}
                </div>

                <div className='flex gap-4'>
                    <div className='flex-1'>
                    </div>
                    <div className='flex content-end'>
                        <div>
                            <ReactStars count={5}
                                    onChange={ratingChanged}
                                    value={formik.values.rate}
                                    size={45}
                                    activeColor="#e0a602" />
                            {formik.errors.rate ? (
                            <div className='error'>{formik.errors.rate}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                
                  
                 
                 
             
                  

                <button className='btn mb-[30px] mx-auto lg:mx-0 self-start cursor-pointer' type='submit'>Add Review Comment</button>
                
            </form>

        </div>
        
      </div>
    </div>
  </motion.section>;
}

export default AddReviews