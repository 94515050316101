import React, {useContext} from 'react';

import Image1 from '../img/gallery/1.jpg';
import Image2 from '../img/gallery/2.jpg';
import Image3 from '../img/gallery/3.jpg';
import Image4 from '../img/gallery/4.jpg';
import Image5 from '../img/gallery/5.jpg';
import Image6 from '../img/gallery/6.jpg';
import Image7 from '../img/gallery/7.jpg';
import Image8 from '../img/gallery/8.jpg';
import Image9 from '../img/gallery/9.jpg';
import Image10 from '../img/gallery/10.jpg';

import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const images = [Image1,Image2,Image3,Image4,Image5,Image6,Image7,Image8,Image9,Image10];

const Gallery = () => {

  const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext);

  return <motion.section 
  initial={{opacity:0, y: '100%'}}
  animate={{opacity:1,y:0}}
  transition={transition1}
  exit={{opacity:0,y: '100%'}}
  className='section xl:overflow-hidden overflow-auto'>
    <div className='container mx-auto relative'>
      <div className='flex flex-col lg:flex-row items-center gap-x-24 text-center lg:text-left pt-24 lg:pt-36 pb-8'>
        
        <motion.div
          initial={{opacity:0, y: '-80%'}}
          animate={{opacity:1,y:0}}
          transition={transition1}
          exit={{opacity:0,y: '-80%'}}
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
         className='flex flex-col lg:items-start z-20'>
          <h1 className='h1'>Gallery</h1>
          {/* <p className='mb-12 max-w-sm'>I am one of the most experienced tour service provider in Sri Lanka.</p> */}
          <Link to={'/contact'} className='btn mb-[30px] mx-auto lg:mx-0'>Contact Me</Link>
        </motion.div>


        <div className='absolute bottom-60 left-40 w-72 h-72 bg-blue-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob' ></div>
        <div className='absolute bottom-40 -left-0 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob animation-delay-2000' ></div>
        <div className='absolute bottom-0 left-20 w-72 h-72 bg-green-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob animation-delay-4000' ></div>

        

        <PhotoProvider>
        <div className='grid grid-cols-2 lg:grid-cols-2 lg:gap-2 xl:overflow-auto h-[600px] pr-5 z-20 m-2' id="style-3">      
          {images.map((img, index) => (
            <PhotoView  src={img}>
                <img className='rounded-xl cursor-pointer border-2 border-slate-800 object-cover h-auto w-full hover:scale-110 transition-all duration-500' src={img} alt="" />
            </PhotoView>
          ))}       
        </div>
        </PhotoProvider>


      </div>
      
    </div>
  </motion.section>;
};

export default Gallery;
