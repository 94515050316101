import React from 'react';
import whatsapp_logo from '../img/whatsapp-svgrepo-com.svg';

const whatsapp_link = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NO}`

function Footer() {
  return (
    <>
    <div className='z-50 fixed bottom-10 right-10 rounded-full h-10 w-10 flex items-center justify-center animate-ping bg-green-400'></div>
        <div className='z-[51] fixed bottom-9 right-9  rounded-full h-12 w-12 flex items-center justify-center text-4xl text-white bg-slate-800'><a href={whatsapp_link}  target='_blank'>
        <img src={whatsapp_logo} alt='' width="35" height="35"/>
        </a>
    </div>
    </>
  )
}

export default Footer