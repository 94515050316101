import React, {useState,useContext,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
//import { useUser } from '../context/UserContext';
import {API} from '../network/api';
import ReactStars from "react-rating-stars-component";
import { format } from 'date-fns';


function Reviews() {

    const [reviews, setReviews] = useState([]);
    //const { user } = useUser();

    useEffect(() => {
        fetchData();
    },[]);


    const fetchData = async () => {
        await API.get('reviews?sort=id:desc').then((response) => {
            console.log(response.data.data);
            setReviews(response.data.data);
        }, (error) => {
            console.log(error);
        });
    }
 
    //const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext);

    return (
        <motion.section
        initial={{opacity:0, y: '100%'}}
        animate={{opacity:1,y:0}}
        transition={transition1}
        exit={{opacity:0,y: '100%'}}
        className='section xl:overflow-hidden overflow-auto'>

       
            <div 
                // onMouseEnter={mouseEnterHandler}
                // onMouseLeave={mouseLeaveHandler}
                className="container mx-auto h-auto xl:h-full xl:relative mt-28 xl:mt-0">

                <div className='flex flex-col lg:flex-col h-auto xl:h-full text-center gap-10 lg:text-left lg:pt-36'>

                    <div className='flex xl:flex-row flex-col justify-between items-center'>
                        <h1 className='h2'>Reviews</h1>
                        <Link to={'/add-review'} className='btn mb-[30px]'> Add New Review </Link>
                    </div>
                    
                   

                    <div className='absolute bottom-60 left-40 w-72 h-72 bg-blue-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob' ></div>
                    <div className='absolute bottom-40 -left-0 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob animation-delay-2000' ></div>
                    <div className='absolute bottom-0 left-20 w-72 h-72 bg-green-300 rounded-full mix-blend-multiply filter blur-xl opacity-60 animate-blob animation-delay-4000' ></div>


                    <div className= 'lg:w-auto flex flex-col lg:items-start border-b-4 xl:overflow-auto h-[600px] xl:pr-10' id="style-3">
                    
                    {reviews.map((item, i) => (
                        <div className='flex flex-col border-b pt-8 pb-8 w-full' key={i}> 
                            

                           <p className='w-[100%] italic text-lg pb-2 text-justify pl-8 pr-8 xl:p-0'>{item.attributes.review}</p>  
                                            
                            <div className='w-[100%] text-lg pr-20 flex justify-end flex-row text-right'>                              
                                <div className='flex flex-col justify-center items-center'>
                                    <ReactStars 
                                    count={5}
                                    //onChange={ratingChanged}
                                    value={item.attributes.vote}
                                    size={28}
                                    activeColor="#e0a602" />

                                    <div className='italic font-bold'>
                                        -- {item.attributes.name} --
                                    </div>
                                    {/* <div className='font-bold text-xs'>
                                        {format(item.attributes.createdAt, 'yyyy-MM-dd kk:mm:ss') }
                                    </div> */}

                                </div>

                                
                                
                            </div>

                        </div>
                    ))}  
                   </div>

                </div>
            </div>
      


        </motion.section>
    )
}

export default Reviews