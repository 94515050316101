import React from 'react';

// import pages

import Home from "../pages/Home";
import About from "../pages/About";
import Gallery from "../pages/Gallery";
import Contact from "../pages/Contact";
import Reviews from "../pages/Reviews";
import AddReviews from "../pages/AddReviews";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import Callback from "../pages/auth/Callback";
import { useUser } from '../context/UserContext';

import {Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
//import GoogleAuthCallback from './GoogleAuthCallback';


const PrivateRoute = ({Component}) => { 
  const { user } = useUser();
  return (user?.isAuthenticated) ? <Component /> : <Navigate to="/auth/login" />
};

const AnimRoutes = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence initial={true} mode='wait'>
    <Routes key={location.pathname} location={location}>
      <Route path="/" element={<Home />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/reviews" element={<Reviews />}></Route>
      <Route path="/gallery" element={<Gallery />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route  path="/add-review" element={<PrivateRoute Component={AddReviews} />} />
      <Route path="/auth/login" element={<Login />}></Route>
      <Route path="/auth/logout" element={<Logout />}></Route>
      <Route path="/auth/google/callback"  element={<Callback />}>    
      </Route>
    </Routes>
    </AnimatePresence>
  );
};

export default AnimRoutes;
