import React, {useState,useEffect, createContext} from 'react';

export const CursorContext = createContext();

const CursorProvider = ({children}) => {
  //cursor Position State
  const [cursorPos, setCursoePos] = useState({
    x:0,
    y:0
  });

  const [cursorBG, setCursoeBG] = useState('default');

  const mobileViewPortIsActive = window.innerWidth < 768;

  useEffect(() => {

    // if(!mobileViewPortIsActive){

    //   const move = (e) => {
    //     setCursoePos({
    //       x: e.clientX,
    //       y: e.clientY,
    //     });
    //   }
    //   window.addEventListener('mousemove',move);

    //   return () => {
    //     window.addEventListener('mousemove',move);
    //   }

    // }else{
    //   setCursoeBG('none');
    // }

    setCursoeBG('none');

  });

  const cursorVarients = {
    default: {
      x: cursorPos.x - 16,
      y: cursorPos.y - 16,
      backgroundColor: '#0e1112',
    },
    text: {
      width: '150px',
      height: '150px',
      x: cursorPos.x - 72,
      y: cursorPos.y - 72,
      backgroundColor: '#fff',
      mixBlendMode: 'difference',
    },
    none:{
      width:0,
      height:0,
      backgroundColor: 'rgba(255,255,255,1)',
    }
  }

  const mouseEnterHandler = () => {
    //setCursoeBG('text')
  }

  const mouseLeaveHandler = () => {
    //setCursoeBG('default')
  }

  return (<CursorContext.Provider value={{cursorVarients,cursorBG,mouseEnterHandler,mouseLeaveHandler}}>{children}</CursorContext.Provider>);
};

export default CursorProvider;
