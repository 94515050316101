import React, {useContext} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {motion} from 'framer-motion';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
import {FaMobileAlt} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi';
import { Store } from 'react-notifications-component';

import {API} from '../network/api';


const contactFormValidation = Yup.object({
  name: Yup
      .string()
      .max(100)
      .required('Name is required'),
  email: Yup
      .string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  message: Yup
      .string()
      .required('Your Message is required')
});

const Contact = () => {

  const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext);


  const formik = useFormik({
      initialValues: {
          name: '',
          email: '',
          message: '',
          submit: null
      },

      validationSchema: contactFormValidation,
      onSubmit: async (values, helpers) => {
          console.log(values);
          // dispatch({ type: 'LOADING' });

          const data = {
            "data":{                 
                name:values.name,
                email:values.email,
                message: values.message
            }
          }


          try {

              await API.post('contacts',data).then((response) => {
                  console.log(response);

                  Store.addNotification({
                      title: "Successfull!",
                      message: "Yor message successfully sent.",
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 3000,
                        onScreen: true
                      }
                  });

                  formik.resetForm();
                
              }, (error) => {
                  console.log(error);
              });
          
         
          } catch (err) {
          
              helpers.setStatus({ success: false });
              helpers.setErrors({ submit: err.message });
              helpers.setSubmitting(false);
              // dispatch({ type: 'LOADED' });
          }
      }

  });

  return <motion.section 
    initial={{opacity:0,y:'100%'}}
    animate={{opacity:1,y:'0'}}
    transition={transition1}
    exit={{opacity:0,y: '100%'}}
    className='section bg-white xl:overflow-hidden overflow-auto'>

    <div className='container h-auto mx-auto'> 
      <div className='flex flex-col-reverse xl:flex-col lg:flex-row h-full item-center justify-start pt-36 gap-x-8 text-center lg:text-left'>
        <motion.div
          initial={{opacity:0,y:'100%'}}
          animate={{opacity:1,y:'0'}}
          transition={transition1}
          exit={{opacity:0,y: '100%'}} 
        className='hidden lg:flex bg-[#eef7f9] absolute bottom-0 left-0 right-0 top-60 z-[-1]'></motion.div>
        <div 
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        className='lg:flex-1 lg:pt-3 px-4'>

            <h1 className='h1'>Contact Me</h1>
            <motion.div 
              initial={{opacity:0,y:'100%'}}
              animate={{opacity:1,y:'0'}}
              transition={{transition:transition1,duration:1.5}}
              exit={{opacity:0,y: '100%'}} 
              className='lg:flex-1 items-end justify-end'>


              <h2 className='h4 flex items-center'><FaMobileAlt className='mr-3'/> +94 77 400 92 28</h2>
              <h2 className='h4 flex items-center'><HiOutlineMail className='mr-3'/>dineshiranga08@gmail.com</h2>
              
            </motion.div>





            <form  className='flex flex-col gap-y-4' onSubmit={formik.handleSubmit}>
                
                  <div>
                    <input
                      autoComplete="off"            
                      name="name"
                      className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full placeholder:text-[#757879]'
                      type="text"
                      placeholder='Your Name'
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className='error'>{formik.errors.name}</div>
                    ) : null}
                  </div>
                 
                  <div>
                  <input 
                      autoComplete="off"
                      name="email"
                      className='outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full placeholder:text-[#757879]'
                      type="text"
                      placeholder='Your Email Address'
                      {...formik.getFieldProps("email")}
                    />
                  {formik.touched.email && formik.errors.email ? (
                    <div className='error'>{formik.errors.email}</div>
                  ) : null}
                  </div>
             
                  <div>
                  <textarea 
                      name="message"
                      className={'outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full placeholder:text-[#757879]' }
                      type="text"
                      placeholder='Your Message'
                      {...formik.getFieldProps("message")}
                    />
                  {formik.touched.message && formik.errors.message ? (
                    <div className='error'>{formik.errors.message}</div>
                  ) : null}
                  </div>

                <button className='btn mb-[30px] mx-auto lg:mx-0 self-start' type='submit'>Send Message</button>
                
            </form>

        </div>
        
      </div>
    </div>
  </motion.section>
};

export default Contact;
