// UserContext.js
import { createContext, useState, useContext, useEffect } from 'react';

const initUser = {
  user: [],
  jwt: null,
  isAuthenticated: false
}

const getInitialState = () => {
  const user = localStorage.getItem('user');
  console.log(JSON.parse(user));
  return user? JSON.parse(user) : initUser;
}


const UserContext = createContext();

const UserProvider = ({ children }) => {

  const [user, setUser] = useState(getInitialState);

  useEffect(()=>{
    localStorage.setItem('user',JSON.stringify(user));
    console.log(user);
  },[user]);
 

  const login = (userData) => {
    setUser({
      user : userData,
      isAuthenticated : true,
      jwt : userData.jwt
    }); 
    console.log(user);
  };

  const logout = async () => {
    setUser({
      user : [],
      isAuthenticated : false,
      jwt : null
    });
  };

  return (
    <UserContext.Provider value={{ login, logout, user  }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUser, UserContext };
